const formatText = (initalText: string): string => {
  if (!initalText) return '';

  return `
    <html>
        <head>
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.5.3/dist/css/bootstrap.min.css" integrity="sha384-TX8t27EcRE3e/ihU7zmQxVncDAy5uIKz4rEkgIXeMed4M0jlfIDPvg6uqKI2xXr2" crossorigin="anonymous">
            <style>
                * {
                    font-family: 'Varela Round',sans-serif;
                    font-size: 16pt;
                }

                img {
                    width: 100%;
                }
                
                .mark {
                    width: 5%;
                    height: 3%;
                    position: absolute;
                    padding-top: 1.5%;
                    opacity: .3;
                }
                
                .erro-ia {
                    background-color: rgba(232,19,19,1) !important;
                    color: white !important;
                }
                
                .erro-comentario {
                    background-color: rgba(0,123,255,1) !important;
                    color: white !important;
                }
                
                    .erro-erro-gramatical {
                    background-color: #d4487a !important;
                    color: #ffffff !important;
                }        
                    .erro-convencao-de-escrita {
                    background-color: #5a5fd6 !important;
                    color: #ffffff !important;
                }        
                    .erro-precisao-vocabular {
                    background-color: #09ba85 !important;
                    color: #ffffff !important;
                }        
                    .erro-escolha-de-registro {
                    background-color: #f2e355 !important;
                    color: #544949 !important;
                }        
                    .erro-conectivos {
                    background-color: #d156f0 !important;
                    color: #ffffff !important;
                }        
                    .erro-sintaxe {
                    background-color: #de8a0d !important;
                    color: #ffffff !important;
                }        
            </style>
        </head>
    <body>
        ${initalText}
        <script src="https://code.jquery.com/jquery-3.4.1.slim.min.js" integrity="sha384-J6qa4849blE2+poT4WnyKhv5vZF5SrPo0iEjwBvKU7imGFAV0wwj1yYfoRSJoZ+n" crossorigin="anonymous"></script>
        <script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js" integrity="sha384-Q6E9RHvbIyZFJoft+2mJbHaEWldlvI9IOYy5n3zV9zzTtmI3UksdQRVvoxMfooAo" crossorigin="anonymous"></script>
        <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js" integrity="sha384-wfSDF2E50Y2D1uUdj0O3uMBJnjuUD4Ih7YwaYd1iqfktj0Uod8GCExl3Og8ifwB6" crossorigin="anonymous"></script>
        <script>
        $(function () {
            $('span').popover({
                trigger: 'hover',
                placement: 'top',
                html: true,
                container: 'body',
                content: function () {
                    return "<p>" + $(this).attr('content') + "<br><b>Sugestão:</b><br>" + $(this).attr('suggestions') + "</p>";
                }
            });
        })
        </script>
    </body>
    </html>
   `;
};
export default formatText;
