import React from 'react';

// eslint-disable-next-line import/no-unresolved
import { TSFixMe } from '../../global';

import './index.scss';

type CustomBtn = {
  // eslint-disable-next-line react/require-default-props
  key?: TSFixMe;
  click: () => void;
  disabled: boolean;
  text: string;
};

const Button = ({ click, disabled, text, key = '01' }: CustomBtn): JSX.Element => (
  <button key={key} disabled={disabled} type="button" onClick={() => click()}>
    {text}
  </button>
);
export default Button;
