import React from 'react';

import './index.scss';

type PartialIntroductionType = {
  vacancyName: string;
};

const PartialIntroduction = ({ vacancyName = '' }: PartialIntroductionType): JSX.Element => (
  <div className="partial-content">
    <section className="introduction-box">
      <h3 className="">
        Parabéns
        <hr />
      </h3>
      <p className="introduction-box--describe">
        Você foi aprovado para a nova etapa do processo seletivo da vaga {vacancyName}. Nessa fase,
        você terá que fazer uma redação sobre um tema pré-determinado que apresentaremos para você.
        Clique em <strong>&quot;Próximo&quot;</strong> para continuar o teste.
      </p>
    </section>
  </div>
);

export default PartialIntroduction;
