import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import './App.scss';
import { EssayProvider } from './contexts/Essay';
import Content from './views/Content';
import Result from './views/Result';
import Home from './views/Home';
import Done from './views/Done';

function App(): JSX.Element {
  return (
    <div className="App">
      <EssayProvider>
        <BrowserRouter>
          <Switch>
            <Route component={Home} path="/" exact />
            <Route
              component={Result}
              path="/:vacancyId/:processStepId/:essayThemeId/:candidateId/result"
              exact
            />
            <Route
              component={Done}
              path="/:vacancyId/:processStepId/:essayThemeId/:candidateId/done"
              exact
            />
            <Route
              component={Content}
              path="/:vacancyId/:processStepId/:essayThemeId/:candidateId"
            />
          </Switch>
        </BrowserRouter>
      </EssayProvider>
    </div>
  );
}

export default App;
