import React, { useCallback, useContext, useEffect } from 'react';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import './index.scss';
import EssayContext from '../../contexts/Essay';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Loader from '../../components/Loader';
import Button from '../../components/Button';
import PartialIntroduction from '../PartialIntroduction';
import PartialTheme from '../PartialTheme';
import PartialSend from '../PartialSend';
import useNavigation from '../../hooks/useNavigation';

const routes = ['', 'theme', 'send'];

const Content = (): JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const { path, params, url } = useRouteMatch();
  const {
    disableGoToPrevious,
    navigateToPrevious,
    disableGoToNext,
    navigateToNext,
  } = useNavigation(routes, location.pathname);
  const { loading, getCandidateData, candidate } = useContext(EssayContext);

  useEffect(() => {
    getCandidateData(params).catch(() => {
      history.push('/');
    });
  }, []);

  const checkEssayStatus = useCallback(
    (c): void => {
      const [essay] = c.candidate.newApiPartnerships.redacaoOnline;

      if (essay.status === 'essay_revised') {
        return history.push(`${url.replace(/\/$/, '')}/result`);
      }

      if (essay.status === 'writing_sent_in_correction') {
        return history.push(`${url.replace(/\/$/, '')}/done`);
      }

      return undefined;
    },
    [history, url],
  );

  useEffect(() => {
    checkEssayStatus(candidate);
  }, [candidate, checkEssayStatus]);

  return (
    <section className="container-app">
      {!(loading === 'resolved') ? (
        <Loader />
      ) : (
        <>
          <Header />
          <section className="content content-body">
            <section className="partial-content menu">
              <Button click={navigateToPrevious} disabled={disableGoToPrevious} text="Anterior" />
              <Button click={navigateToNext} disabled={disableGoToNext} text="Próximo" />
            </section>
            <Switch>
              <Route
                path={`${path}`}
                render={() => (
                  <PartialIntroduction vacancyName={candidate.fieldsVacancy.vacancyName} />
                )}
                exact
              />
              <Route component={PartialTheme} path={`${path}/theme`} />
              <Route path={`${path}/send`} render={() => <PartialSend candidate={candidate.candidate} />}  />
            </Switch>
          </section>
          <Footer />
        </>
      )}
    </section>
  );
};

export default Content;
