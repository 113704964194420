import React, { createContext, useEffect, useState } from 'react';

import API from '../api';
// eslint-disable-next-line
import { TSFixMe } from '../global';
import Candidate, { MotivationalText } from '../models/candidate';

type EssayContextData = {
  loading: 'idle' | 'rejected' | 'resolved' | 'running';
  candidate: Candidate;
  getCandidateData(params: TSFixMe): Promise<void>;
  selectedTheme: MotivationalText;
  setSelectedTheme(param: MotivationalText): void;
};

type LoadingStates = 'idle' | 'rejected' | 'resolved' | 'running';

const EssayContext = createContext<EssayContextData>({} as EssayContextData);

export const EssayProvider = ({ children }: TSFixMe): JSX.Element => {
  const [loading, setLoading] = useState<LoadingStates>('idle');
  const [candidate, setCandidate] = useState<Candidate>({
    candidate: {
      vacancy: '',
      _id: '',
      newApiPartnerships: {
        _id: '',
        redacaoOnline: [
          {
            _id: '',
            essayThemeId: '',
            processStepId: '',
            redacaoURL: '',
            status: '',
          },
        ],
      },
      user: {
        _id: '',
        basicInformation: {
          fullName: '',
        },
      },
    },
    environment: 'taqe',
    fieldsVacancy: {
      companyLogo: '',
      companyName: '',
      stepName: '',
      vacancyName: '',
    },
    theme: {
      slug: null,
      title: '',
      type_id: 1,
      updated_at: '',
    },
  } as Candidate);
  const [selectedTheme, setSelectedTheme] = useState(
    JSON.parse(localStorage.getItem('persistSelectedTheme') || '{}'),
  );

  useEffect(() => {
    localStorage.setItem('persistSelectedTheme', JSON.stringify(selectedTheme));
  }, [selectedTheme]);

  const getCandidateData = async (params: TSFixMe) => {
    setLoading('running');
    const response = await API.get(
      `public/vacancies/essayMotivatingTexts/${params.essayThemeId}/${params.candidateId}/${params.processStepId}`,
    );
    const requestedEssay = response.data.candidate.newApiPartnerships.redacaoOnline.filter(
      (essay: TSFixMe) =>
        Number(essay.essayThemeId) === Number(params.essayThemeId) &&
        essay.processStepId === params.processStepId,
    );
    const seralizedResponse = {
      ...response.data,
      candidate: {
        ...response.data.candidate,
        newApiPartnerships: {
          redacaoOnline: requestedEssay,
        },
      },
    };
    setCandidate(seralizedResponse);
    setLoading('resolved');
  };

  return (
    <EssayContext.Provider
      value={{
        loading,
        candidate,
        getCandidateData,
        selectedTheme,
        setSelectedTheme,
      }}
    >
      {children}
    </EssayContext.Provider>
  );
};

export default EssayContext;
