import React from 'react';

import './index.scss';

const Home = (): JSX.Element => (
  <section className="home">
    <div className="img-dots" />

    <h1>Olá, infelizmente você está no lugar errado.</h1>
    <h2>Erro 404 ; (</h2>

    <h3 className="">
      Mas fique calmo, deve ser apenas um erro de digitação, confira a URL e tente novamente!
    </h3>
  </section>
);

export default Home;
