import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import API from '../../api';
import Loader from '../../components/Loader';
import EssayContext from '../../contexts/Essay';
// eslint-disable-next-line import/no-unresolved
import { TSFixMe } from '../../global';
import useForm from '../../hooks/useForm';

import './index.scss';

const PartialSend = ({ candidate }: TSFixMe): JSX.Element => {
  const history = useHistory();
  const { params, url } = useRouteMatch<TSFixMe>();
  const { value: essayText, watchValue: watchText, isValid } = useForm('');
  const [state, setState] = useState({
    loading: false,
    error: false,
    warning: false,
    errorCode: 0,
  });
  const {
    candidate: { theme },
    selectedTheme,
  } = useContext(EssayContext);

  useEffect(() => {
    if (!selectedTheme.id) {
      setState({ ...state, warning: true });
    }
  }, [selectedTheme]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const essay = candidate.newApiPartnerships.redacaoOnline.find((redacao: TSFixMe) => String(redacao.processStepId) === params.processStepId);
    const correctionType = essay ? essay.correctionType : theme.type_id;
    const API_URL = `/public/vacancies/${selectedTheme.id}/${correctionType}/${params.candidateId}/${params.processStepId}`;
    setState({
      ...state,
      loading: true,
    });

    API.post(API_URL, {
      essayText,
    })
      .then(() => {
        setState({
          ...state,
          loading: false,
        });
        Object.keys(localStorage).map((key) => localStorage.removeItem(key));
        history.push(`${url.replace(/\/send\/?$/, '')}/done`);
      })
      .catch(({ response }) => {
        setState({
          loading: false,
          error: true,
          warning: false,
          errorCode: response.data.code,
        });
      });
  };

  const showError = () => (
    <section className="box error">
      {`Ops! Infelizmente não conseguimos completar o envio da sua redação, por favor, tente novamente em alguns minutos. Caso o erro persista, por favor, entre em contato com a gente! Status: ${state.errorCode}`}
    </section>
  );

  const showWarning = () => (
    <section className="box warning">
      Ops! Parece que você esqueceu de escolher uma proposta na tela anterior, por favor, volte e
      selecione uma, assim que você tiver selecionado retorne para essa página e liberaremos o
      campos pra você escrever sua redação!
    </section>
  );

  return (
    <>
      {state.loading ? (
        <Loader />
      ) : (
        <div className="partial-content">
          {state.error && showError()}
          {state.warning && showWarning()}
          <div className="send-page">
            <h4 className="">Hora da redação!</h4>
            <hr />
            <p className="send-introduction">
              Agora, a partir da leitura dos textos motivadores e com base nos conhecimentos
              construídos ao longo de sua formação, redija um texto dissertativo-argumentativo em
              modalidade escrita formal da língua portuguesa sobre o tema{' '}
              <strong>&quot;{theme.title}&quot;</strong> com base na proposta{' '}
              <strong>{selectedTheme.title}</strong>, apresentando proposta de intervenção que
              respeite os direitos humanos. Selecione, organize e relacione, de forma coerente e
              coesa argumentos e fatos para defesa do seu ponto de vista. Lembre-se de ficar atento
              á pontuação. Depois de pronto, revise seu trabalho. Quando terminar, clique em{' '}
              <strong>&quot;Enviar&quot;</strong>. Boa sorte!
            </p>
            <form className="send-form" onSubmit={(e) => handleSubmit(e)}>
              <textarea
                className="send-form__textarea"
                cols={20}
                disabled={!selectedTheme.title}
                name="text"
                placeholder="Digite seu texto aqui..."
                rows={30}
                value={essayText}
                onChange={(e) => watchText(e)}
              />
              <div className="send-form__btn-area">
                <button
                  className="btn btn-success m-1 mb-1 text-right"
                  disabled={isValid}
                  type="submit"
                >
                  Enviar
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default PartialSend;
