import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Loader from '../../components/Loader';
import EssayContext from '../../contexts/Essay';
// eslint-disable-next-line import/no-unresolved
import { TSFixMe } from '../../global';
import formatText from '../../helpers/formatText';
import './index.scss';

const Result = (): JSX.Element => {
  const { params, url } = useRouteMatch();
  const history = useHistory();
  const { candidate, getCandidateData, loading } = useContext(EssayContext);
  const [essay, setEssay] = useState<TSFixMe>({});
  const [essayReviewed, setEssayReviewed] = useState<TSFixMe>({});

  useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle
    if (!candidate.candidate._id) {
      getCandidateData(params).catch(() => {
        history.push('/');
      });
    }
  }, []);

  useEffect(() => {
    if (loading === 'resolved') {
      const { essay: essayFromCandidate } = candidate.candidate.newApiPartnerships.redacaoOnline[0];

      if (!essayFromCandidate) {
        history.push(`${url.replace(/\/result\/?$/, '')}/done`);
      }
      setEssay(essayFromCandidate);
    }
  }, [candidate, loading]);

  useEffect(() => {
    setEssayReviewed(formatText(essay.correcao));
  }, [essay]);
  
  return (
    <>
      {!(loading === 'resolved') ? (
        <Loader />
      ) : (
        <div className="container-app">
          <Header />
          <main className="result-body">
            <section className="result-body__userInfo">
              <h1>
                Olá, <span className="hightligth bold"> {candidate?.candidate.user?.basicInformation?.fullName} !</span>
              </h1>
              <h2 className="bold">SUA CORREÇÃO JÁ ESTÁ DISPONÍVEL!</h2>
            </section>
            <section className="result-body__essayInfo">
              <div>
                <p className="hightligth bold">Tema da redação</p>
                <span className="title">{`"${candidate.theme.title}"`}</span>
              </div>
              <div>
                <p className="hightligth bold">Notas do Corretor</p>
                <span>{essay.comentario_geral}</span>
              </div>
              <div>
                <p className="hightligth bold">Nota Final</p>
                <span>{essay.nota_geral}</span>
              </div>
            </section>

            <iframe srcDoc={essayReviewed} title="Correção" />

            <section className="result-body__compenencies">
              {essay.competencia_1_comentario && (
                <section className="comtepence_commentary">
                  <strong>Comentário competência 1: </strong>
                  <span>{essay.competencia_1_comentario}</span>
                </section>
              )}
              {essay.competencia_1_nota && (
                <span className="competencie_grade">{`Nota: ${essay.competencia_1_nota}`}</span>
              )}
              {essay.competencia_2_comentario && (
                <section className="comtepence_commentary">
                  <strong>Comentário competência 2: </strong>
                  <span>{essay.competencia_2_comentario}</span>
                </section>
              )}
              {essay.competencia_2_nota && (
                <span className="competencie_grade">{`Nota: ${essay.competencia_2_nota}`}</span>
              )}
              {essay.competencia_3_comentario && (
                <section className="comtepence_commentary">
                  <strong>Comentário competência 3: </strong>
                  <span>{essay.competencia_3_comentario}</span>
                </section>
              )}
              {essay.competencia_3_nota && (
                <span className="competencie_grade">{`Nota: ${essay.competencia_3_nota}`}</span>
              )}
              {essay.competencia_4_comentario && (
                <section className="comtepence_commentary">
                  <strong>Comentário competência 4: </strong>
                  <span>{essay.competencia_4_comentario}</span>
                </section>
              )}
              {essay.competencia_4_nota && (
                <span className="competencie_grade">{`Nota: ${essay.competencia_4_nota}`}</span>
              )}
              {essay.competencia_5_comentario && (
                <section className="comtepence_commentary">
                  <strong>Comentário comeptência 5: </strong>
                  <span>{essay.competencia_5_comentario}</span>
                </section>
              )}
              {essay.competencia_5_nota && (
                <span className="competencie_grade">{`Nota: ${essay.competencia_5_nota}`}</span>
              )}
            </section>
          </main>
          <Footer />
        </div>
      )}
    </>
  );
};

export default Result;
