import React from 'react';

import './index.scss';

const Loader = (): JSX.Element => (
  <section className="loader-page">
    <div className="loadingio-spinner-blocks-5lvmud3uq25">
      <div className="ldio-qntsbvumzud">
        <div style={{ left: 38, top: 38, animationDelay: '0s' }} />
        <div style={{ left: 80, top: 38, animationDelay: '0.125s' }} />
        <div style={{ left: 122, top: 38, animationDelay: '0.25s' }} />
        <div style={{ left: 38, top: 80, animationDelay: '0.875s' }} />
        <div style={{ left: 122, top: 80, animationDelay: '0.375s' }} />
        <div style={{ left: 38, top: 122, animationDelay: '0.75s' }} />
        <div style={{ left: 80, top: 122, animationDelay: '0.625s' }} />
        <div style={{ left: 122, top: 122, animationDelay: '0.5s' }} />
      </div>
    </div>
  </section>
);

export default Loader;
