import { useCallback, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

type UseNavigation = {
  disableGoToPrevious: boolean;
  disableGoToNext: boolean;
  navigateToNext(): void;
  navigateToPrevious(): void;
};

const useNavigation = (routes: string[] = [], currentRoute = ''): UseNavigation => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const [currentPage, setCurrentPage] = useState(
    currentRoute.split('/')[currentRoute.split('/').length - 1],
  );
  const [routesToGo, setRoutesToGo] = useState(routes);
  const [routesToBack, setRoutesToBack] = useState(routes);
  const [disableGoToNext, setDisableGoToNext] = useState(false);
  const [disableGoToPrevious, setDisableGoToPrevious] = useState(false);

  const handleRoute = useCallback(
    (firstRoute, lastRoute, atualPage) => {
      if (lastRoute === atualPage) {
        setDisableGoToNext((oldState) => !oldState);
      } else if (firstRoute === atualPage) {
        setDisableGoToPrevious((oldState) => !oldState);
      } else {
        setDisableGoToPrevious(false);
        setDisableGoToNext(false);
      }
    },
    [setDisableGoToPrevious, setDisableGoToNext],
  );

  useEffect(() => {
    const lastRoute = routes[routes.length - 1];
    const [firstRoute] = routes;
    handleRoute(firstRoute, lastRoute, currentPage);
  }, [routes, currentPage, handleRoute]);

  useEffect(() => {
    const copyRoutes = [...routes];

    if (!routes.includes(currentPage)) setCurrentPage('');
    setRoutesToGo(copyRoutes.splice(routes.indexOf(currentPage) + 1));
    setRoutesToBack(copyRoutes.slice(0, routes.indexOf(currentPage) + 1));
  }, [currentPage, routes]);

  const navigateToNext = (): void => {
    const [nextPage] = routesToGo;
    setCurrentPage(nextPage);

    return history.push(`${url.replace(/\/$/, '')}/${nextPage}`);
  };

  const navigateToPrevious = (): void => {
    const previousPage = routesToBack[routesToBack.indexOf(currentPage) - 1];
    setCurrentPage(previousPage);

    return history.push(`${url.replace(/\/$/, '')}/${previousPage}`);
  };

  return {
    disableGoToPrevious,
    disableGoToNext,
    navigateToNext,
    navigateToPrevious,
  };
};

export default useNavigation;
