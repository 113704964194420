import React, { useContext } from 'react';

import EssayContext from '../../contexts/Essay';

import './index.scss';

const Header = (): JSX.Element => {
  const { candidate } = useContext(EssayContext);

  return (
    <header className="header">
      <h1 className="header-h1">{`Processo Seletivo ${candidate.environment}`}</h1>
      <h2 className="header-h2">
        {`${candidate.fieldsVacancy.companyName} - ${candidate.fieldsVacancy.vacancyName}`}
      </h2>
      <h3 className="header-h3">{candidate.fieldsVacancy?.stepName}</h3>
    </header>
  );
};

export default Header;
