const getEnvironment = (): string => {
  const urls: Record<string, string> = {
    'taqe-production': 'https://api.taqe.com.br/v2',
    'ciee-production': 'https://ciee-api.taqe.com.br/v2',
    'ciee-homolog': 'https://ciee-api.taqe.mobi/v2',
    'taqe-homolog': 'https://api.taqe.mobi/v2',
    local: 'http://localhost:3000/v2',
  };

  return urls[process.env.REACT_APP_STAGE || 'local'] || 'http://localhost:3000/v2';
};

export default getEnvironment;
