import { useState, useEffect } from 'react';

// eslint-disable-next-line
import { TSFixMe } from '../global';

type UseForm = {
  value: string;
  watchValue(e: TSFixMe): void;
  isValid: boolean;
};

const useForm = (initalState: string): UseForm => {
  const [value, setValue] = useState(initalState || localStorage.getItem('persistEssay') || '');

  useEffect(() => {
    localStorage.setItem('persistEssay', value);
  }, [value]);

  const watchValue = (e: TSFixMe) => {
    setValue(e.target.value);
  };

  return { value, watchValue, isValid: !value.replace(/\W/g, '') };
};
export default useForm;
