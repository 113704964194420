import React, { useContext, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import ReactHtmlParser from 'react-html-parser';

// eslint-disable-next-line
import { TSFixMe } from '../../global';
import EssayContext from '../../contexts/Essay';

import './index.scss';

const PartialTheme = (): JSX.Element => {
  const [theme, setTheme] = useState<Record<string, TSFixMe>>({});
  const [textHelper, setTextHelper] = useState<JSX.Element[] | null>(null);
  const [selectedBtnId, setSelectedBtnId] = useState<number | null>(null);
  const { candidate, setSelectedTheme, selectedTheme } = useContext(EssayContext);

  useEffect(() => {
    setTheme(candidate.theme);
  }, [candidate]);

  useEffect(() => {
    if (selectedTheme) {
      setSelectedBtnId(selectedTheme.id);
      setTextHelper(ReactHtmlParser(selectedTheme.motivacional_text));
    }
  }, [selectedTheme]);

  const handleSelectedTheme = (text: TSFixMe) => {
    setSelectedBtnId(text.id);
    setTextHelper(ReactHtmlParser(text.motivacional_text));
    setSelectedTheme(text);
  };

  return (
    <section className="partial-content">
      <section className="theme-page">
        <section className="theme-box--describe">
          Você deverá selecionar <em>somente uma</em> das propostas para basear a sua redação.
        </section>
        <h4 className="title">{theme.title || ''}</h4>
        <hr />
        <section className="menu-theme">
          {theme.motivational_texts &&
            theme.motivational_texts.map((text: TSFixMe) => (
              <button
                key={uuid()}
                // ref={selectedBtn}
                className={`
                  ${text.id === selectedBtnId ? 'selected' : ''}
                `}
                type="button"
                onClick={() => handleSelectedTheme(text)}
              >
                {text.title}
              </button>
            ))}
        </section>
        <section className="theme-example">{textHelper}</section>
      </section>
    </section>
  );
};

export default PartialTheme;
