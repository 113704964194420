import React from 'react';

import './index.scss';

const Done = (): JSX.Element => (
  <section className="home">
    <div className="img-dots" />

    <h3 className="">Você terminou!</h3>
    <h1>OBRIGADO, EM BREVE ENTRAREMOS EM CONTATO</h1>
  </section>
);

export default Done;
