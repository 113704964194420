import React, { useContext } from 'react';

import EssayContext from '../../contexts/Essay';
import './index.scss';

const Footer = (): JSX.Element => {
  const {
    candidate: { fieldsVacancy, environment = '' },
  } = useContext(EssayContext);

  return (
    <footer className="footer">
      <img className="logo" alt={`Logo da empresa ${environment}`} src={fieldsVacancy.companyLogo} />
    </footer>
  );
};

export default Footer;
